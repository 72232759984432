@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto:400,700&display=swap");
@import "src/theme/theme-variables";
@import "./mixin.scss";

@font-face {
  // primary font
  font-family: "Segoe UI";
  src: url("../assets/fonts/SEGOEUI.TTF") format("truetype");
}

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  font-size: 14px;
  font-family: "Open Sans", sans-serif !important;
}

.primary-font {
  font-family: "Segoe UI", sans-serif !important;
}

.secondary-font {
  font-family: "Open Sans", sans-serif !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.pr-20 {
  margin-right: 4rem;
}

.pr-15 {
  margin-right: 3rem;
}

.pr-10 {
  margin-right: 2rem;
}

.pr-5 {
  margin-right: 1rem;
}

.pr-2 {
  margin-right: 0.5rem;
}

.mr-50 {
  margin-right: 50px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-12 {
  margin-left: 12px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-22 {
  margin-bottom: 22px;
}

.font-36 {
  font-size: 38px;
}

.font-30 {
  font-size: 30px;
}

.font-12 {
  font-size: 12px;
}

.bold {
  font-weight: bold;
}

.font-weight {
  font-weight: 400;
}

.width-80 {
  width: 80px;
}

.width-25 {
  width: 25%;
}

.color-grey {
  color: #a4b3dc;
}

.align-right {
  text-align: $secondary-direction;
}

.displayNone {
  display: none;
}

.agoRedColor {
  color: red !important;
}

// custom asterisk for required dropdown in parts form
.parts-drop-down {
  .ng-clear-wrapper {
    display: none;
  }

  .ng-select {
    .ng-placeholder {
      &::after {
        content: " *";
        color: red;
      }
    }
  }
}

/**
  menu style here to overcome angular shadow dom without having to write deep rules
 */
.menu-style {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#324462+0,455776+100 */
  background: #fff; /* Old browsers */
  color: #fff;
  border-radius: 0 10px 10px 0;
  padding: 47px 0;
  // no need for the following gradient background.
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   // background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), transparent);
  // }

  .menu-button {
    transition: all 0.3s ease-in-out;
    height: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 24px;
    outline: none;

    mat-icon {
      color: #fff;
    }
  }

  .mat-expansion-panel {
    background: transparent;
  }

  &.small {
    .menu-button {
      height: 64px;

      mat-icon {
        font-size: 24px;
        color: $color-primary;
      }
    }

    .mat-list-item-content {
      justify-content: center;
    }

    .icon {
      background: mat.get-color-from-palette($app-primary, 200);
      width: 32px;
      height: 32px;
      border-radius: 5px;
      display: flex;
      padding: 0;
      margin: 16px auto;
      justify-content: center;
      align-items: center;
    }

    mat-icon {
      display: flex;
      color: $color-primary;
      font-size: 18px;
      justify-content: center;
      align-items: center;
    }

    .mat-expansion-panel-header {
      padding: 0;
    }

    .mat-expansion-indicator {
      display: none;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  &.large {
    .mat-list-item-content {
      justify-content: left;
    }

    .icon {
      background-color: mat.get-color-from-palette($app-primary, 200);
      width: 32px;
      height: 32px;
      border-radius: 5px;
      display: flex;
      padding: 0;
      margin-left: var(--secondary-margin);
      margin-right: var(--primary-margin);
      justify-content: center;
      align-items: center;
    }

    mat-icon:not(.profile-menu-button-icon) {
      display: flex;
      color: $color-primary;
      font-size: 18px;
      justify-content: center;
      align-items: center;
    }

    .mat-expansion-indicator::after {
      color: $color-primary;
    }

    .mat-expansion-panel-header {
      padding: 0 16px;
      width: 90%;
      margin: 0 auto;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 7px;
      }
    }

    .mat-expansion-panel-body {
      padding: 0 47px 16px;
      margin: 5px 0;
    }

    .mat-list-base .mat-list-item,
    .mat-list-base .mat-list-option {
      width: 90%;
      margin: 0 auto;
    }
  }

  a,
  mat-icon,
  span {
    color: $color-dark;
    align-items: center;
  }

  mat-expansion-panel {
    mat-expansion-panel-header {
      &.active {
        background: mat.get-color-from-palette($app-primary, 200);

        .icon {
          background-color: $color-primary;

          mat-icon {
            color: #fff;

            svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .mat-list-item {
    &.active {
      background: mat.get-color-from-palette($app-primary, 200);

      .icon {
        background-color: $color-primary;

        mat-icon {
          color: #fff;

          svg {
            fill: #fff;
          }
        }
      }
    }

    font-size: 14px;
  }
}

mat-sidenav-container {
  mat-sidenav-content {
    margin-top: 64px;
    overflow: unset !important;
  }

  &.menu-state {
    &.small {
      mat-sidenav-content {
        margin-left: var(--primary-margin-80) !important;
        margin-right: var(--secondary-margin-80) !important;
        width: calc(100% - 65px) !important;
      }

      .core-form-header {
        width: calc(100% - 255px);
      }

      .mat-tab-navigator {
        padding: 0 20% 0 14%;
      }
    }

    &.large {
      mat-sidenav-content {
        margin-left: var(--menu-size-primary) !important;
        margin-right: var(--menu-size-secondary) !important;
        width: calc(100% - 270px) !important;

        .mat-tab-navigator {
          padding: 0 17%;
          max-width: calc(100% - 365px);
          margin-bottom: 20px;
        }

        @media only screen and (max-width: 768px) {
          margin-left: var(--menu-size-primary-ipad) !important;
          margin-right: var(--menu-size-secondary-ipad) !important;
          width: calc(100% - 235px) !important;
        }

        z-index: inherit;
      }
    }
  }
}

.search-input {
  font-size: 14px;
  width: auto;

  mat-form-field {
    width: 100%;
    background: #f1f4f9;
    border: none;
    padding: 0 24px;
    height: 40px;
    border-radius: 4px;

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix,
    .mat-form-field-flex,
    .mat-form-field-wrapper,
    .mat-input-element,
    .mat-form-field-label-wrapper,
    .mat-form-field-label {
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      border: none;
      display: flex;
      top: 0;
    }

    .mat-form-field-label {
      mat-label {
        display: none;
      }

      &.mat-empty {
        mat-label {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &.lg {
    width: 670px;
  }

  // todo respo style for search box
  &.md {
  }

  &.sm {
  }

  &.xs {
  }
}

.menu-brand {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px 16px;

  .logo {
    outline: 0;
    cursor: pointer;
  }

  img {
    // filter: brightness(0) invert(1);
  }

  .filter-none {
    filter: none;
  }

  .menuSizeToggler {
    display: flex;
    justify-content: center;
    align-items: center;

    .rtl-view,
    .ltr-view {
      display: flex;

      mat-icon {
        width: 0.6rem;
        font-size: 25px;
        font-weight: bold;

        &:last-child {
          opacity: 0.4;
        }
      }
    }
  }
}

.text-white {
  color: #fff !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: $color-primary !important;
}

.mat-drawer {
  left: var(--position-absolute-primary) !important;
  right: var(--position-absolute-secondary) !important;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0 0 6px #6177871a;
}

.mat-drawer-container {
  background: $body-bg;
}

.mat-drawer-content {
  height: calc(100% - 64px) !important;
}

.main-container {
  display: flex;
  height: 100%;
  align-items: flex-start;
  // width: calc(var(--main-container-width) - 18px);
  // @media only screen and (max-width: 768px) {
  //   width: calc(var(--main-container-width) - 1px);
  // }
  transition: all 0.3s ease-in-out;
  //overflow: auto;

  .main-container-inner {
    margin-bottom: 30px;
    padding: 15px;
    width: 100%;
    //overflow-y: hidden;
    @media only screen and (max-width: 768px) {
      padding: 30px 15px;
    }
  }

  /**
  fix for large tables
   */
  //.core-list {
  //  flex-direction: column;
  //  box-sizing: border-box;
  //  display: flex;
  //  flex: 1 1 0%;
  //  background-color: white;
  //  padding: 10px 15px;
  //  border-radius: 0 0 8px 8px;
  //
  //}
}

//mat-tab-group {
//  &.vertical-tabs {
//    flex-direction: row;
//
//    mat-tab-header {
//      .mat-tab-labels {
//        flex-direction: column;
//      }
//    }
//
//    .mat-tab-header-pagination {
//      display: none;
//    }
//
//    .mat-tab-body-content,
//    .mat-tab-body-wrapper {
//      width: 100%;
//    }
//
//    .mat-ink-bar {
//      display: none;
//      visibility: visible;
//      left: 0;
//      width: 5px !important;
//      height: 48px;
//    }
//  }
//}
.mat-tab-body-content {
  overflow: hidden !important;
}

.vertical-mat-tab-nav-bar-container {
  .vertical-mat-tab-nav-bar {
    flex-direction: column;
    display: flex;
  }

  .mat-tab-header-pagination {
    display: none !important;
  }

  .mat-tab-links {
    flex-direction: column;
  }

  .mat-tab-list {
    transform: none !important;
  }

  .mat-tab-label-active {
    border-left: 5px solid #273c75;
    background-color: #f1f4f9;
  }

  mat-ink-bar.mat-ink-bar {
    display: none;
  }

  .mat-tab-nav-bar {
    border-bottom: none !important;
  }
}

.container-card {
  //width: 270px;
  padding: 10px 0 !important;
  border-radius: 8px !important;
}

.section-name {
  padding: 10px var(--secondary-padding) 10px var(--primary-padding);
  font-size: 14px;
  font-weight: bold;
  color: #324462;
  display: flex;
  align-items: baseline;

  span {
    margin-right: var(--secondary-margin);
    margin-left: var(--primary-margin);
    vertical-align: sub;
  }

  mat-icon {
    color: #f1f4f9;
    background-color: #273c75;
    text-align: center;
    border-radius: 50%;
    //padding: 2px;

    svg {
      vertical-align: middle;
    }
  }
}

a.item-link {
  width: 100%;
  height: 32px;
  justify-content: space-between;
  margin: 4px 0;

  span {
    padding-left: 27px;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.page_heading,
.page_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  position: relative;
  min-height: 64px;

  .title {
    color: #000 !important;
    font-size: 26px;
    margin: 0;
    padding: 0 !important;
    font-weight: bold;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    margin-right: 0 !important;

    button:not(:last-child) {
      margin: 0 16px;
      margin-right: 0;
    }

    .btn-primary {
      background-color: #1e69e4;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32);
      width: 86px;
      height: 40px;

      &.mat-stroked-button {
        background-color: #fff;
        border-color: #1e69e4;
        color: #1e69e4;
      }

      &[disabled] {
        background-color: rgba(151, 165, 203, 0.5);
        border-color: transparent;
        color: #fff;
        box-shadow: none;
      }
    }

    .btn-secondary {
      color: #3d4a58;
      border: 1px solid #3d4a58;
    }

    .rounded {
      border-radius: 50%;

      &.size-32px {
        width: 32px;
        height: 32px;
        min-width: auto;
        justify-content: center;
        align-items: center;
        padding: 0;
        display: flex;

        .mat-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        mat-icon {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .table-multi-actions {
    position: absolute;
    top: 0;
    left: 0;
    //width: 100%;
    height: 100%;
    background: #000;
    display: flex;
    width: calc(100% + 60px);
    margin-left: -30px;
    padding: 0 25px;
    align-items: center;
    z-index: 3;

    .count {
      color: #fff;
      display: flex;
      height: 100%;
      align-items: center;
      margin-right: var(--primary-margin);
      margin-left: var(--secondary-margin);
    }

    .actions {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 25px;

      button:not(:last-child) {
        //margin-right: 16px;
        margin-right: var(--primary-margin);
        margin-left: var(--secondary-margin);
      }

      .btn-light {
        background: #fff;
        color: #000;

        mat-icon {
          color: #000;
        }
      }
    }
  }
}

//button[disabled] {
//  color: rgba(0, 0, 0, 0.26) !important;
//  background-color: rgba(0, 0, 0, 0.12) !important;
//}

.form-spacing {
  &.lg {
    padding: 0 15%;
  }
}

.border-0 {
  border: none !important;
}

.btn-cancel {
  color: #3552ad !important;
  border: none !important;
  border-radius: 5px !important;
  width: 86px;
  height: 40px;
}

.btn-save {
  border: none !important;
  border-radius: 20px !important;
}

.password-guide {
  ul {
    padding: 0;
    margin-top: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      min-height: 24px;
    }
  }

  mat-icon {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .square-icon {
    width: 5px;
    height: 5px;
    background: #273c75;
    display: block;
    margin-right: 19px;
  }

  ul {
    li {
      &.success {
        color: $color-success;

        mat-icon {
          color: $color-success;
        }
      }

      &.danger {
        color: #f44336;

        mat-icon {
          color: #f44336;
        }
      }
    }
  }
}

.validation-errors {
  display: flex;
  flex-direction: column;
}

.mat-form-field-required-marker {
  color: red;
}

//.headerSmallSize {
//  //min-height: auto;
//  //margin-top: -82px;
//
//  .buttons-container {
//    padding: 0 !important;
//    margin: 0 !important;
//  }
//}

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

/**
browser hack override for chrome auto fill style
not recommended
 */
input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  box-shadow: 0 0 0 50px white inset; /* your box-shadow */
  -webkit-text-fill-color: #333;
}

breadcrumb {
  color: $rock-blue;

  .breadcrumb {
    display: inline-flex;
    align-items: center;
    z-index: 2;

    span {
      font-size: 16px;
      //font-weight: bold;
    }

    .icon {
      display: flex;
      align-items: center;
    }

    // &:last-child {
    //   color: #273c75;
    // }
  }

  .mat-button {
    padding: 0 12px;
  }
}

.fileUpload {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;

  .image-container {
    position: relative;
    display: block;
    padding: 15px;
    border: 1px dashed $rock-blue;
    border-radius: 12px;
    width: 170px;
    height: 170px;
    background-color: #ecf2f7;
    color: $rock-blue;

    //span {
    //  display: block;
    //  margin: 5px 0 5px 10px;
    //  text-align: center;
    //}

    img {
      border-radius: 15px;
      overflow: hidden;
      display: block;
      max-width: 80%;
      margin: 0 auto;
      z-index: 1;
    }

    .buttons-container {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      z-index: 5;
      padding: 5px;

      button {
        margin-left: 5px;
        width: 24px;
        height: 24px;
        background-color: #fff;
        transition: all 0.2s ease-in-out;

        &.mat-mini-fab .mat-button-wrapper {
          display: flex;
          padding: 0;
          line-height: initial;

          mat-icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        mat-icon {
          font-size: 14px;
        }

        .edit {
        }

        .delete {
        }

        &:hover {
          &.edit {
            background-color: $color-primary;

            svg {
              color: #fff;
            }
          }

          &.delete {
            background-color: $color-danger;

            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .input-container {
    display: flex;

    label {
      width: 100%;
      border-radius: 10px;
      border: 1px dashed #2a3348;
      margin: 10px 0;
      padding: 10px;
      text-align: center;
      cursor: pointer;
    }
  }

  &.disabled {
    .input-container {
      label {
        border-color: rgba(0, 0, 0, 0.38);
        color: rgba(0, 0, 0, 0.38);
        cursor: not-allowed;
        display: none;
      }
    }

    .image-container {
      border-color: rgba(0, 0, 0, 0.38);
      cursor: not-allowed;

      img {
        filter: opacity(0.5);
      }
    }
  }
}

//.image-upload {
//  .input-container {
//    label {
//      margin-top: -170px;
//      width: 170px;
//      height: 170px;
//      border-radius: 12px;
//      border: 0;
//      z-index: 2;
//    }
//  }
//}

.default-form {
  //min-width: 150px; // new design system for Afaqy Products requires full form width
  //max-width: 570px;
  width: 100%;

  //todo style form sections
  .form-section {
    // padding-top: 15px;
    // padding-bottom: 15px;

    .form-section-title {
      font-weight: bold;
      padding-bottom: 5px;
      color: $color-primary !important;
    }

    .form-section-content {
    }

    .grid-container {
      display: grid;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 1em;
  }

  .field-container {
    //padding-top: 15px;
    position: relative;
    padding-bottom: 0;
    margin-right: 24px;
    margin-left: 24px;
    .required-asterisk {
      position: absolute;
      right: 0;
      top: calc(50% - 8px);

      &.inline {
        position: relative;
        right: auto;
        top: auto;
        display: inline-flex;
        padding-left: var(--primary-padding-5);
        padding-right: var(--secondary-padding-5);
      }
    }

    &.radio-group {
      mat-radio-group {
        mat-radio-button {
          margin: 0 5px;
          transition: all 0.2s ease-in-out;
        }

        &.radio-group-vertical {
          display: flex;
          flex-direction: column;
          margin: 6px 0;

          mat-radio-button {
            margin: 8px 0;
          }
        }

        &.radio-group-horizontal {
          mat-radio-button {
            margin: 0 5px;
          }

          &.radio-style1 {
            border: 1px solid #ececec;
            border-radius: 7px;
            margin: 0;
            overflow: hidden;
            padding: 0;
            display: flex;
            flex-direction: row;
            margin-bottom: 8px;

            mat-radio-button {
              margin: 0;
              padding: 0;
              flex: 1;

              label {
                padding: 15px;
              }

              .mat-radio-outer-circle {
                border-color: rgba(0, 0, 0, 0.2);
              }

              &.mat-radio-checked {
                color: #fff;
                background-color: $color-primary;

                .mat-radio-outer-circle {
                  border-color: #fff;
                }

                .mat-radio-inner-circle {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .upload-file-hidden {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      opacity: 0;
      cursor: pointer;
    }

    &.image-upload {
      width: 170px;
      height: 170px;
      margin: 0 auto;
      position: relative;

      .image-container {
        .img-label {
          z-index: 1;
          margin-bottom: 14px;
        }
      }

      .image-container,
      .input-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .input-container {
        label {
          border: 0;
        }
      }
    }

    &.file_upload_details {
      .input-container {
        background-color: $porcelain;
        border: 1px dashed $rock-blue;
        width: 80%;
        border-radius: 8px;
        color: $rock-blue;
        padding: 15px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: #fff;
          border: 1px dashed #273c75;
        }

        .icon {
          margin-right: 15px;
        }

        .labels {
          text-align: left;

          .primary-label {
            font-weight: bold;
            padding-bottom: 5px;
          }
        }

        .action-button {
          margin-left: auto;

          button {
            background-color: $porcelain;
            border: 1px solid $rock-blue;
            color: $rock-blue;
          }
        }
      }
    }

    .mat-button-toggle-group {
      width: auto;
      padding: 1px;
      border: 1px solid #5373d8;
      border-radius: 6px;
      justify-content: space-between;

      .mat-button-toggle:first-of-type {
        width: 65px;
        height: 33px;
        border-radius: 6px;
      }

      .mat-button-toggle:last-of-type {
        width: 85px;
        height: 33px;
        border-radius: 6px;
      }

      button {
        color: #5373d8;
      }

      .mat-button-toggle-checked {
        background: $color-primary;
        border-radius: 4%;

        .mat-button-toggle-label-content {
          color: #fff !important;
        }
      }
    }
  }
}

.radio-group {
  mat-radio-group {
    mat-radio-button {
      margin: 0 5px;
      transition: all 0.2s ease-in-out;
    }

    &.radio-group-vertical {
      display: flex;
      flex-direction: column;
      margin: 6px 0;

      mat-radio-button {
        margin: 8px 0;
      }
    }

    &.radio-group-horizontal {
      mat-radio-button {
        margin: 0 5px;
      }

      &.radio-style1 {
        border: 1px solid #1e69e4;
        border-radius: 7px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;

        mat-radio-button {
          margin: 0;
          padding: 0;
          flex: 1;
          color: #1e69e4;

          label {
            padding: 6px 15px;
            display: flex;
            justify-content: center;
          }

          .mat-radio-outer-circle {
            border-color: rgba(0, 0, 0, 0.2);
          }

          &.mat-radio-checked {
            color: #fff;
            background-color: $color-primary;

            .mat-radio-outer-circle {
              border-color: #fff;
            }

            .mat-radio-inner-circle {
              background-color: #fff;
            }
          }
        }

        &.no-radio-circle {
          .mat-radio-container {
            display: none;
          }

          mat-radio-button {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

mat-dialog-container {
  border-radius: 9px !important;
  overflow: hidden !important;
  padding: 0 !important;
  width: 406px !important;
  height: 240px !important;
}

.form-popup-custom-dialog {
  // custom style for for form-popup dialog
  mat-dialog-container {
    border-radius: 9px !important;
    overflow: hidden !important;
    padding: 30px 47px !important;
    height: auto !important;
  }
}
app-filters-button {
  .filterLine {
    border-bottom: 1px solid #707070;
    padding-bottom: 14px;
    margin-bottom: 14px;
    // &:last-child {
    //   border-bottom: none;
    //   padding-bottom: 14px;
    //   margin-bottom: 14px;
    // }
  }
  .input {
    border-bottom: 1px solid #707070;
  }
}

app-list-grid,
app-list-grid-material {
  .tbl-content {
    tr {
      background: #fff;
      transition: all 0.2s ease-in-out;

      .actions-buttons {
        transition: all 0.2s ease-in-out;

        mat-icon {
          transition: all 0.2s ease-in-out;
        }
      }

      &.selected-row {
        background: #324462 !important;

        &:hover {
          background: #324462 !important;
        }

        *:not(.actions-buttons) {
          color: #fff;
        }

        .actions-buttons {
          mat-icon {
            background: #fff;
            color: #273c75;

            &.mdi-icon {
              fill: #273c75;
            }
          }
        }

        .tags-style-1 {
          .array-item {
            span {
              color: #324462;
            }
          }
        }
      }
    }
  }
}

.table-style {
  border-collapse: collapse;

  .tbl-header {
    tr {
      background-color: $thead-color;
    }

    th {
      font-weight: bold;
      color: $color-primary;
      // padding: 0 15px;

      &:first-of-type {
        border-start-start-radius: 5px;
        border-end-start-radius: 5px;
      }

      &:last-of-type {
        border-end-end-radius: 5px;
        border-start-end-radius: 5px;
      }

      &.select-all {
        width: 40px;
        padding: 0 10px;
      }

      .table-column-header {
        padding: 10px;
        // padding-left: 0 !important;
        font-size: 12px;
      }

      &.actions-head {
        width: 120px;
        color: #6a737c;

        .filter-action {
          cursor: pointer;
        }
      }

      .sortable {
        justify-content: space-between;
      }
    }

    th.th-filters-inline {
      padding: 0 5px;
    }

    .hide-name {
      & > div > span {
        display: none;
      }
    }
  }

  tr {
    // &:hover,
    // &.locked {
    //   background: $even-tr-color;
    // }
    &:nth-child(even) {
      background-color: $even-tr-color;
    }

    td {
      padding: 0 10px;
      height: 50px;

      &.select-row {
        width: 40px;
        padding: 0 10px;
        text-align: center;
      }

      transition: all ease-in-out 0.1s;

      &:first-of-type {
        border-start-start-radius: 5px;
        border-end-start-radius: 5px;
      }

      &:last-of-type {
        border-end-end-radius: 5px;
        border-start-end-radius: 5px;
      }
    }
  }

  .cell-container {
    // &.fill-height { always centeralize the cell-inner-conteiner content
    .cell-inner-container {
      display: flex;
      align-items: center;
      height: 100%;
      // justify-content: center;
    }

    // }

    &.yellow-background {
      background-color: #fff5e5;
    }
  }

  .item-prefix,
  .item-suffix,
  .item-content {
    display: inline-flex;

    &.clearfix {
      display: block;
      margin: 0;
    }

    .item-additional-content {
      > div:not(:first-child) {
      }

      &.comma-separator-items {
        app-list-grid-content.not-empty ~ app-list-grid-content.not-empty {
          &::before {
            content: ",";
            display: inline-flex;
            margin-right: 3px;
          }
        }
      }

      &.space-separator-items {
        app-list-grid-content.not-empty ~ app-list-grid-content.not-empty {
          &::before {
            content: " ";
            display: inline-flex;
            margin-right: 3px;
          }
        }
      }

      &.vehicles-space-separator-items {
        app-list-grid-content.not-empty ~ app-list-grid-content.not-empty {
          margin-left: 3px;
        }

        .slashBefore {
          &::before {
            content: "/";
            display: inline-flex;
            padding-right: 3px;
          }
        }
      }

      &.slash-separator-items {
        &::before {
          content: "/";
          display: inline-flex;
          margin-right: 3px;
        }
      }

      //
      //&.comma-seperator {
      //
      //
      //  &.true-value {
      //    > span {
      //
      //
      //
      //
      //      }
      //    }
      //  }
      //}
    }
  }

  // todo: custom styling for table cell prefix and suffix and content
  .item-content {
    .vertical-helper {
      display: flex;
      flex-direction: column;
    }
  }

  .item-suffix {
    margin-left: var(--secondary-table-spacing);
    margin-right: var(--primary-table-spacing);
    display: inline-flex;
  }

  .item-prefix {
    margin-right: var(--secondary-table-spacing);
    margin-left: var(--primary-table-spacing);
    display: inline-flex;
  }

  .cell-display-type {
    &.color {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      overflow: hidden;
      display: inline-flex;
      margin-right: 5px;
      margin-left: 5px;
    }

    &.icon {
      margin-right: 5px;
      margin-left: 5px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      mat-icon {
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.array {
      //todo style array items displayed in list

      &.tags-style {
        .array-item {
          //border: 1px solid #dcdde1;
          padding: 5px 8px;
          border-radius: 6px;
          display: inline-flex;
          background-color: #f2f2f2;
          color: #555;
          margin: 2px 0;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }

      &.images-array-style {
        .array-item {
          display: inline-flex;
          width: 34px;
          height: 34px;

          span {
            display: flex;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #fff;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            width: 34px;
            height: 34px;
            margin: 0;
            padding: 0;
            overflow: hidden;
            transition: all 0.2s ease-in-out;
          }

          &:hover {
            span {
              z-index: 2;
              transform: scale(1.2);
            }
          }

          img {
            width: 32px;
            height: 32px;
            margin: 0;
            padding: 0;
          }

          &:not(:first-child) {
            margin-left: -10px;
          }
        }
      }

      .array-trimBy {
        display: inline-flex;
      }
    }
  }

  .clickable {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }

  .boolean-icon {
    mat-icon {
      fill: $rock-blue;
      color: $rock-blue;
    }

    &.true {
      mat-icon {
        fill: #1e69e4;
        color: #1e69e4;
      }
    }
  }

  .item-border > div > span {
    border: 1px solid #dcdde1;
    border-radius: 20px;
    padding: 4px 8px;
  }

  .number-background {
    .number-dark {
      background-color: #273c75;
      color: white;
      padding: 5px 12px;
      border-radius: 20px;
      display: inline-block;
    }

    .number-light {
      background-color: $rock-blue;
      color: white;
      padding: 5px 12px;
      border-radius: 20px;
      display: inline-block;
    }
  }

  .blue-link {
    color: $color-primary !important;
  }

  .black-link {
    color: black;
  }

  &.no_data {
    width: 100%;
    display: flex;
    flex-direction: column;

    .tbl-header {
      width: 100%;
      display: flex;

      tr {
        width: 100%;
        display: flex;

        th {
          display: flex;
          align-items: center;
          flex: 1;

          &:not(.actions-head) {
            //width: inherit !important;
          }

          &.th-hidden {
            display: none;
          }

          &.actions-head {
            margin-left: auto;
          }
        }
      }
    }

    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;

      tr {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 50vh;
        align-items: center;

        td {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  // end table-style
}

.filters-inline {
  //background: red;

  app-filters-inline {
    width: calc(100%);

    .filter-inline-container {
      width: 100%;
      display: flex;
      flex: auto !important;

      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .filter-clear-button {
      }

      .mat-form-field-underline {
        display: none !important;
      }

      .mat-input-element {
        color: #273c75;
      }

      form {
        width: 100%;

        .mat-form-field-prefix .mat-icon,
        .mat-form-field-suffix .mat-icon {
          font-size: 16px;
          line-height: 1.4;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline {
          color: #dbdbdb;
          background: #fefefe;
        }

        .mat-form-field {
          width: 100%;

          .mat-form-field-flex {
            .mat-form-field-infix {
              border-top: 0.5em solid transparent;
              padding: 0.5em 0;

              // placeholder weight
              ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                opacity: 1; /* Firefox */
                font-weight: bold;
              }

              ::input-placeholder {
                /* Microsoft Edge */
                font-weight: bold;
              }
            }
          }

          label {
            color: red;
          }
        }
      }
    }
  }
}

/**
app-settings selected items bar fix
 */

app-settings {
  .headerSmallSize {
    .table-multi-actions {
      margin-left: -390px;
      width: calc(100% + 270px + 125px);
    }
  }
}

app-pagination {
  width: 100%;
}

.cell-display-type {
  &.color {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.mat-card-header-text {
  margin: 0 !important;
}

.export-list-btn {
  margin-right: var(--primary-margin);
  margin-left: var(--secondary-margin);
  border-radius: 20px !important;
  display: flex !important;
  justify-content: center !important;

  div.mat-select-arrow-wrapper {
    display: none;
  }

  &.mat-select {
    display: inline;
  }
}

.table-multi-actions {
  .actions {
    .export-list-btn {
      mat-icon.mat-icon {
        margin-bottom: 8px;
      }
    }
  }
}

.menu-trigger-icon {
  background-color: white;
  border: 1px solid $rock-blue !important;
  color: #3d4a58 !important;
}

.export-menu {
  margin-top: -51px;
  //width: 212px;
  padding-top: 5px;
  border-radius: 7px !important;

  .export-menu-title {
    padding-left: var(--primary-padding);
    padding-right: var(--secondary-padding);
    color: $rock-blue;
    font-size: 12px;
  }

  button {
    mat-icon {
      color: #324462;
    }
  }

  .in-menu-button {
    color: #273c75;
    height: 36px;
    margin: 4px 0;
    display: flex;
    align-items: center;
    //justify-content: space-between;

    &:hover {
      background-color: #ecf2f7 !important;
    }

    .in-menu-icon {
      color: #273c75 !important;
      background-color: #ecf2f7;
      border-radius: 50%;
      padding: 4px 2px;
      text-align: center;
      margin: 0;
    }

    .in-menu-span {
      color: #273c75 !important;
      padding-left: 9px;
      line-height: initial;
    }

    .share-btn {
      padding-right: 0;
    }
  }
}

.photo-gallery-card {
  .photo {
    figure.mat-figure {
      height: 169px !important;
    }
  }

  .contact-person {
    .mat-figure {
      justify-content: start !important;
    }
  }
}

.toggle-view {
  background-color: transparent;
  outline: none;
  border: none;

  span {
    cursor: pointer;
    margin-right: 27px;
  }

  .show-more {
    position: absolute;
    top: 145px;
  }

  .show-less {
    position: absolute;
    bottom: 5px;
  }
}

.comment {
  div.mat-list-text {
    margin-left: 10px;
  }
}

.comment-form {
  .mat-form-field-underline {
    display: none;
  }
}

.doc-icon {
  color: $rock-blue;
  border: 1px solid $rock-blue;
  border-radius: 4px;
  margin-right: 12px;
  height: 24px;
  width: 24px;
}

.blue-gradient {
  &.active {
    background-color: #1e69e4 !important;
  }
}

.custom-value {
  .true-value {
    font-size: 12px;
  }
}

.main-menu {
  .mdi-icon {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  mat-expansion-panel {
    mat-list {
      .mat-list-item {
        margin: 10px 0;
      }

      mat-list {
        .mat-list-item {
          margin: 10px var(--secondary-margin) 10px var(--primary-margin);
        }
      }
    }
  }

  .user-card {
    .menu-user-image {
      display: flex;
      align-items: center;

      img {
        width: 43px;
        height: 43px;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 0 3px #0000000f;
        border: 3px solid #fff;
      }
    }

    .options {
      margin-left: var(--position-absolute-secondary);
      margin-right: var(--position-absolute-primary);
    }

    .profile-menu-button {
      // margin-left: var(--primary-margin-80);
      // margin-right: var(--secondary-margin-80);

      @media only screen and (max-width: 1368px) {
        // margin-left: 70px;
      }

      &.text-primary {
        color: $color-primary !important;

        mat-icon {
          color: $color-primary;
        }
      }
    }
  }
}

.table-image-thumb {
  width: 32px;
  height: 32px;
}

.dummyDataLoader {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  width: 100px;
  height: 100px;

  mat-icon {
    font-size: 70px;
  }
}

.filter-container {
  //max-width: 365px;
  width: 365px;
  height: 100%;

  .main-card {
    width: 100%;
    border-radius: 10px;
    height: 100%;

    //max-height: 620px;
    overflow-y: auto;

    mat-card-header {
      font-size: 14px;
      color: #3d4a58;

      mat-icon {
        margin-right: 10px;
        margin-top: 2px;
        font-size: 16px;
      }

      //::ng-deep .mat-card-header-text {
      //  margin: 0 !important;
      //}
    }

    mat-form-field {
      width: 100%;
    }

    .submit_button {
      background-color: #1e69e4;
      width: 100%;
      justify-content: center;
      border-radius: 20px;
      color: white;
      font-size: 14px;
      bottom: 4%;
      position: absolute;
      left: 0;
    }
  }
}

.driver-menu {
  width: 250px;
  background-color: red;
}

.status-bg {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
}

.odometer-lastupdate {
  color: $rock-blue;
}

.over-due {
  color: red;
  margin-left: 24px;
}

.insurance-amount-text {
  color: #1e69e4;
}

.avoid-odometer {
  display: inline-flex;
}

.hint-icon {
  color: #aaa;
}

.service-task-desc {
  color: #707070;
  font-size: 12px;
}

.service-task-name {
  color: #3d3d3d;
  font-size: 14px;
}

.link-text {
  text-decoration: none;
  //word-break: break-all;
}

.fixed_table_actions {
}

.section-heading {
  font-size: 24px;
}

.tags-style-1 {
  display: inline-flex;
  flex-wrap: wrap;

  .array-item {
    background-color: #d8dce4;
    color: #202020;
    padding: 5px 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
  }
}

.badge {
  background-color: $color-secondary;
  padding: 3px 12px;
  margin: 0 5px;
  color: #fff;
  border-radius: 25px;
}

mat-icon.service-delete-icon {
  border: 1px solid $rock-blue;
  border-radius: 6px;
  height: 28px;
  width: 28px;

  svg {
    margin-top: 3px !important;
  }
}

.time-picker-icon {
  color: #757575;
  margin-right: 4px;
  font-size: 16px !important;
}

.bg-gray {
  background-color: $gray-600;
}

.field-style-custom {
  &.rounded {
    border-radius: 7px;

    &.reset-side {
      &.bottom {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.width-small {
    width: 100px;

    &.fix-10px {
      width: 110px;
    }
  }

  &.width-medium {
    width: 205px;
  }

  &.text-gray {
    color: rgba(0, 0, 0, 0.38);
  }

  &.suffix-gray {
    color: rgba(0, 0, 0, 0.38);
  }

  &.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);

    .mat-form-field-underline {
      display: none;
    }
  }

  &.padding-bottom-0 {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

.new-line {
  * {
    color: red;
  }

  &::before {
    display: block;
    content: "";
    clear: both;
    flex-basis: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.separator-pl-2 {
  > div {
    padding-left: 5px;
  }
}

app-login {
  .login-fields {
    .checkBox {
      display: flex;
      justify-content: flex-start;
      direction: $secondary-flex-direction;

      .mat-checkbox-inner-container {
        margin-right: var(--primary-margin-8);
        margin-left: var(--secondary-margin-8);
      }
    }
  }
}

.mat-tab-nav-bar {
  border-bottom: none;
}

.debug {
  border: 1px solid red;
}

.pay-button {
  background-color: green;
  color: white;
  padding: 5px 15px;
}

.notification-cell {
  border: 1px solid #a1a1a1;
}

.notification-date {
  font-size: 13px;
  color: #707070;
  // position: absolute;
  // right: 0;
  // top: 2px;
}

.eye-icon {
  color: #1e69e4 !important;
}

.read-notifications {
  border: 0.5px solid #efefef;
  padding: 8px;
  background-color: white;
  cursor: pointer;
}

.unread-notifications {
  background-color: #efefef;
  border-left: 6px solid #3c4e6c;
  padding: 8px;
  cursor: pointer;
}

.color-primary {
  color: $color-primary;
}

.color-dark {
  color: $color-dark;
}

.list-checks {
  mat-icon {
    fill: #c72f2fed !important;
  }

  .true {
    mat-icon {
      fill: #3dca92 !important;
    }
  }
}

// Core-list styling.
.core-list {
  background-color: white;
  box-shadow: 0px 3px 9px #00000029;
  border-radius: 10px;
  padding: 15px 20px;

  .page_heading,
  .page_footer {
    margin-bottom: 20px;
    min-height: auto;
  }
}

// start new design system for Afaqy Products
.primary-button {
  background: $color-primary;
  color: #fff;
}

.core-form-style {
  .form-body-content {
    background: #fff;
    padding: 15px 0;
    border-radius: 8px;
  }
}

.mat-form-field-disabled {
  // uncomment later in case design system needs it in any other module that clients form
  //input {
  //  color: black !important;
  //}

  .mat-form-field-flex {
    .mat-form-field-outline {
      background: #f9f9f9;
    }
  }
}

mat-label {
  font-weight: bold;
  font-size: 12px;
}

.mat-tab-label {
  font-weight: bold;
  padding: 0 15px !important;
}

.mat-tab-disabled {
  color: $color-primary;
}

.mat-tab-label-active {
  background: #fff !important;
  color: $color-primary;
  border-radius: 5px;
}

.mat-tab-header {
  margin-right: 24px;
  width: 161px;
}

.mat-tab-body-wrapper {
  max-width: 66%;
  border-radius: 10px;
  border: 1px solid #e6ecf5;
}

.mat-tab-navigator {
  margin-top: 15px;
  margin-bottom: 40px !important;
  margin-left: calc(17% + 5px);
  position: relative;
  //.previous {
  //  color: #242121 !important;
  //}
  .next {
    //float: right;
    position: absolute;
    right: 0;
    width: 100px;
    height: 40px;
    border-radius: 5px;
  }

  .previous {
    position: absolute;
    left: 0;
  }
}

.mat-slide-toggle-input-container {
  width: 49%;
  margin-bottom: 15px;
  display: inline-block;
}

.tabs-next-button.mat-button-disabled {
  background: grey;
  color: #fff;
}

.form-input-size {
  font-size: 12px;
}

.ng-select .ng-select-container {
  min-height: 41px !important;

  .ng-value-container .ng-input {
    position: absolute;
    left: 0;
    width: 100%;
    /* height: 50%; */
    padding: 10px;
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: 41px !important;
  display: flex;
  justify-content: center;
  // line-height: 0;
  align-items: center;
}

.core-form-header {
  width: 100%;
  border-bottom: 1px solid #24212114;
}

.mat-button-toggle-group {
  height: 37px !important;
  margin-top: 3px;

  span {
    line-height: 35px !important;
  }
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none !important;
}

.mat-slide-toggle-thumb {
  width: 13px !important;
  height: 13px !important;
  transform: translate(50%, 50%);
  box-shadow: none !important;
}

.mat-slide-toggle-bar {
  background-color: #cad1e7;
  border-radius: 15px !important;
  width: 39px !important;
  height: 22px !important;
}

.mat-slide-toggle-thumb-container {
  top: -2px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #5373d8;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fff;
}

.mat-slide-toggle-content {
  overflow: visible !important;
}

.mat-slide-toggle-content {
  overflow: visible !important;
}

.tbl-header {
  height: 50px;
  vertical-align: baseline;
}

.action-thead {
  font-size: 12px;
}

.breadcrumb {
  margin-bottom: 10px;
  mat-icon {
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .xng-breadcrumb-link {
    color: $color-dark;
  }
  .xng-breadcrumb-trail {
    color: $color-primary;
  }
}
app-date-picker {
  .mat-form-field-suffix {
    display: flex;
    align-items: center;
  }
}
// end new design system for Afaqy Products
