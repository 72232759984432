@import "src/theme/theme-variables";

canvas:not(#color-strip, #color-block) {
  width: 100% !important;
}

app-shell {
  width: 100%;
  overflow: auto;
}

mat-checkbox {
  &.custom-style-1 {
    .mat-checkbox-frame {
      border-width: 1px;
      border-color: $rock-blue;
      border-radius: 4px;
    }

    &.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
    &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
      background-color: transparent !important;

      .mat-checkbox-checkmark {
        fill: #273c75 !important;
      }

      .mat-checkbox-checkmark-path {
        stroke: #273c75 !important;
      }
    }
  }
}

.selected-row {
  mat-checkbox {
    &.custom-style-1 {
      &.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
      &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
        background-color: #fff !important;
        border-radius: 4px;
      }
    }
  }
}

app-filters-inline {
  mat-form-field {
    .mat-form-field-underline {
      background-color: transparent !important;
    }
  }
}

app-date-range {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.color-picker-style1 {
  .color-picker {
    border: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    overflow: hidden;
    flex-direction: column !important;
    width: 176px !important;
    padding: 8px;

    &.open {
      display: flex !important;
    }

    .saturation-lightness {
      display: flex !important;
      order: 2;
      border-radius: 8px;
      //margin: 0 8px;
      //width: calc(100% - 16px);
    }

    .hue-alpha {
      display: flex !important;
      order: 3;

      .left {
        display: none;
      }

      .right {
        .hue {
          border-radius: 8px;
          margin-bottom: 0;

          //transform: scaleX(1.25);
          .cursor {
            //transform: scaleX(.85);
          }
        }
      }
    }

    .hex-text {
      display: none !important;
    }

    .type-policy {
      display: none !important;
    }

    .preset-area {
      display: flex;
      align-self: flex-start;
      order: 1;
      padding: 0 0 10px;

      hr {
        display: none;
      }

      .preset-label {
        display: none;
      }

      .preset-color {
        width: 24px;
        height: 24px;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border: 0;
        margin: 8px;
      }
    }

    .box {
      padding: 0 !important;
    }

    .right {
      padding: 0 !important;
    }
  }

  .color-circle {
    width: 24px;
    height: 24px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .color-square {
    width: 24px;
    height: 24px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    margin-right: 8px;
  }

  //.mat-form-field-label-wrapper {
  //  transition: all 0.1s ease-in-out;
  //}
  //
  //.mat-form-field-should-float .mat-form-field-label-wrapper {
  //  left: -28px;
  //}
}

.toast-info {
  background-color: #1e69e4;
}

.mat-input-element:disabled {
  cursor: not-allowed;
}

.mat-icon-button {
  mat-icon {
    &.mdi-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}

#mainProgressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .ng-meteor {
    box-shadow: none !important;
  }
}

ng-select {
  &.ng-select-style1 {
    .ng-select-container {
      padding-top: 5px;
    }

    &.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper {
      z-index: 9;
    }

    &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
      background-color: #d8dce4;
      color: #202020;
      //padding: 2px 5px 2px 15px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-content: center;

      span {
        display: flex;
        justify-content: space-around;
        padding-right: var(--secondary-padding);
        padding-left: var(--primary-padding);
        align-items: center;
        line-height: initial;
      }

      .ng-value-icon {
        padding-right: var(--primary-padding-5);
        padding-left: var(--secondary-padding-5);
        z-index: 9;
        display: flex;

        mat-icon {
          justify-content: center;
          align-items: center;
          line-height: initial;
          display: flex;

          svg {
            color: $rock-blue;
          }
        }
      }
    }

    //&.required-asterisk-inline-before {
    //  .ng-value-container .ng-placeholder {
    //    &::before {
    //      content: "*";
    //      display: block;
    //      top: 0;
    //      //left: 50px;
    //      right: var(--primary-position-minus-10);
    //      left: var(--secondary-position-minus-10);
    //      color: #f44336;
    //      position: absolute;
    //    }
    //  }
    //}
  }
}

.ng-select-style1 {
  .ng_select_prefix {
    display: inline-flex;
    align-items: center;

    .cell-display-type {
      &.color {
        display: inline-flex;
        width: 8px;
        height: 8px;
      }

      &.img {
        display: inline-flex;
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 10px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

mat-tooltip-component {
  .white-tooltip {
    background-color: #fff;
    box-shadow: 0 4px 14px rgba(151, 165, 203, 0.43);
    color: #3d3d3d;
  }
}

.tooltip-template {
  &.d-none {
    display: none !important;
  }
}

.tippy-tooltip {
  &.white-tooltip-theme {
    color: #3d3d3d;
    box-shadow: 0 4px 14px rgba(151, 165, 203, 0.5);
    //border: 1px solid rgba(151, 165, 203, .5);
    padding: 15px;
    border-radius: 8px;

    .tippy-content {
      .tooltip-template-inner {
        .title {
          text-align: $primary-text-align;
          margin-bottom: 15px;
          color: $rock-blue;
        }

        //&.d-none {
        //  display: flex !important;
        //}
      }

      .array-item {
        border: 1px solid #dcdde1;
        padding: 5px 8px;
        border-radius: 15px;
        margin-bottom: 5px;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }

      .w-fixed {
        max-width: 380px;
        flex-flow: wrap;
      }
    }

    .tippy-backdrop {
      background-color: #fff;
    }
  }
}

.filters-container-large {
  width: 365px;
}

.dialog-container {
  &.import-dialog {
    text-align: center;

    .input-container {
      background-color: $porcelain;
      border: 1px dashed $rock-blue;
      width: 80%;
      border-radius: 8px;
      color: $rock-blue;
      padding: 15px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #fff;
        border: 1px dashed #273c75;
      }

      .icon {
        margin-right: 15px;
      }

      .labels {
        text-align: left;

        .primary-label {
          font-weight: bold;
          padding-bottom: 5px;
        }
      }

      .action-button {
        margin-left: auto;

        button {
          background-color: $porcelain;
          border: 1px solid $rock-blue;
          color: $rock-blue;
        }
      }
    }

    .title {
      color: #273c75;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .mat-flat-button {
      &.download_sample_file {
        border-radius: 8px;
        font-size: 24px;
        background-color: #19ac65;
        padding: 8px 14px;
        margin-bottom: 45px;
      }
    }

    .upload-file-hidden {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;

      &.edit {
        z-index: 3;
      }
    }

    &.md {
      width: 770px;
    }

    .errors-container {
      width: 80%;
      background-color: #f8d7da;
      margin: 0 auto;
      border-radius: 8px;
      padding: 8px 20px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.ngx-slider .ngx-slider-pointer {
  // left: 18px !important;
  width: 10px !important;
  height: 10px !important;
  top: -3px !important;
  background-color: #273c75 !important;

  &:after {
    content: none !important;
    background-color: #fff !important;
  }

  &:focus {
    outline: none !important;
  }
}

.ngx-slider .ngx-slider-selection {
  background-color: #273c75 !important;
}

.dark-color {
  color: #273c75;
}

.range-form-field .mat-form-field-infix {
  min-width: unset !important;
  max-width: unset !important;
  width: 330px;
}

.buttons-container .mat-tab-nav-bar,
.buttons-container .mat-tab-header {
  border: none;
  margin-right: 15px;
}

.buttons-container .mat-tab-link {
  margin-right: 15px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 90px;
  padding: 0;
  color: $biscay;
}

.buttons-container .mat-tab-label-active {
  opacity: 1;
}

// work-order-line-items-task specific styling
.buttons-container .task .mat-tab-nav-bar,
.buttons-container .mat-tab-header {
  margin-right: 0 !important;
}

.buttons-container .task .mat-tab-label {
  min-width: 81px !important;
}

.buttons-container .task .mat-tab-group.mat-primary .mat-ink-bar,
.buttons-container .task .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  width: 103px !important;
}

.buttons-container .task .mat-tab-header-pagination {
  display: none !important;
}

.task .infinite-scrolling,
.lineitems-list .infinite-scrolling {
  height: unset !important;
  max-height: 397px !important;
}

//

.buttons-container .mat-tab-group.mat-primary .mat-ink-bar,
.buttons-container .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $biscay;
}

// .lb-outerContainer {
//     width: 65% !important;
//     // height: 70vh !important;
//     background: transparent !important;
//     .lb-image {
//         width: 100% !important;
//         // height: 80vh !important;
//     }
// }
// .lb-dataContainer {
//     width: 65% !important;
// }

.ng_select_container {
  padding-bottom: 15px;

  &.error {
    ng-select {
      &.ng-select-focused {
        .ng-select-container {
          &:after {
            border-color: #f44336 !important;
            border-width: 2px !important;
          }
        }
      }

      .ng-select-container {
        &:after {
          border-color: #f44336 !important;
        }

        .ng-value-container {
          .ng-placeholder {
            color: #f44336 !important;
          }
        }
      }
    }
  }

  mat-error {
    position: relative;
  }

  ng-select {
  }
}

// max-w-form used on work-order-form
.max-w-form {
  max-width: 100%;

  .ng-placeholder {
    padding: 0 16px;
  }

  input {
    padding-left: 0 16px;
  }
}

.no-pd .mat-list-item-content {
  padding: 0px !important;
}

// max-w-form used on work-order-form

// caused conflict with drop downs and ng select and headers
// todo: find better z-index organize for all app overlapping components
//.cdk-overlay-container {
//  z-index: 1060;  // default 1000
//}

.ng-dropdown-panel {
  z-index: 1061;

  .ng-option {
    display: flex !important;
    justify-content: $secondary-flex-justify;
    //justify-content: flex-end;
  }

  .ng-dropdown-header {
    display: flex !important;
    justify-content: $secondary-flex-justify;
  }

  .ng-dropdown-panel-items {
    .multiselect-option-container {
      direction: $secondary-flex-direction;

      .dropdown-checkbox,
      .dropdown-color {
        margin-right: var(--primary-margin-4);
        margin-left: var(--secondary-margin-4);
      }
    }
  }
}

.tbl-content {
  table {
    &.table-style {
    }
  }

  &.infinite-scrolling {
    height: 350px;
    overflow: auto;
    position: relative;
  }
}

table {
  &.table-style {
    // max-width: 100%;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 0;
    border-spacing: 0;

    .actions-data-row {
      padding-left: 10px;
    }

    td {
      vertical-align: middle;
    }

    &.infinite-scrolling {
      width: 100%;
      table-layout: inherit;

      tbody {
        //display: block;
        //height: 350px;
        //overflow: auto;
        position: relative;
      }

      thead,
      tbody tr {
        //display: table;
        width: 100%;
        //table-layout: fixed; /* even columns width , fix width of table too */

        td {
          //border: 1px solid red; //debug mode

          &.hidden-td {
            padding: 0;
            border-bottom: none;
          }
        }
      }

      thead {
        width: 100%;
        /* scrollbar is average 1em/16px width, remove it from thead width */

        tr {
          th {
            //border: 1px solid red; //debug mode

            top: 0px;
            position: sticky;
            z-index: 100;
            //background-color: #fff !important;
            background-color: #f7f7f7 !important;
            border-bottom: 1px solid #eeeeee;
          }
        }
      }
    }
  }

  .hidden-td {
    height: 0;
    padding: 0;
  }
}

.line-item-card {
  ng-select.ng-select-style1.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    display: none;
  }

  .task-issues {
    ng-select.ng-select-style1.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
      display: inline-flex;
    }
  }

  .ng-clear-wrapper {
    display: none;
  }
}

.field-container {
  mat-form-field {
    .mat-form-field-infix {
      width: inherit;
    }
  }
}

.filterSideNavContainer {
  z-index: 1000 !important;
  height: 0;

  .filterSideNav {
    //left: auto !important;
    //right: 0 !important;

    right: var(--position-absolute-primary) !important;
    left: var(--position-absolute-secondary) !important;
  }
}

.ng-arrow-wrapper {
  bottom: 12px !important;

  .ng-arrow {
    color: $color-primary !important;
  }
}

.notificationsSideNav {
  .mat-drawer-inner-container {
    &::-webkit-scrollbar {
      width: 1px;
    }
  }
}

.mat-form-field-subscript-wrapper {
  display: flex !important;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 13px 0 !important;
}

.ng-clear-wrapper {
  bottom: 10px !important;
}

.mat-calendar-controls {
  color: $color-primary;

  .mat-calendar-arrow {
    fill: $color-primary;
  }

  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    color: $color-primary;
  }
}

// You should find another way to disable closing dialog on click outside ite area
//.cdk-overlay-backdrop {
//  pointer-events: none;
//}
.mat-dialog-close[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

// Hide input type=number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-primary;
  border-radius: 5px;
}

// new design system start
.ng-dropdown-panel {
  .ng-dropdown-header {
    display: none !important;
  }
}

rg-gauge-chart span {
  text-align: left;
}

rg-gauge-chart .gauge-chart__label {
  text-align: center;
  color: #777777;
}

.primary-text-color {
  color: #3552ad !important;
}

.red-text-color {
  color: #d41616 !important;
}

.gray-text-color {
  color: #777777 !important;
}

.toast-container {
  .toast-icon {
    color: #31b743 !important;
  }

  .toast-success {
    background-image: url("../assets/svg/check-circle-solid.svg");
    border-left: 8px solid #31b743 !important;
    @include custom-toastr;
  }

  .toast-error {
    background-image: url("../assets/svg/exclamation-circle-solid.svg");
    border-left: 8px solid red !important;
    @include custom-toastr;
  }

  .toast-warning {
    background-image: url("../assets/svg/exclamation-circle-solid-warning.svg");
    border-left: 8px solid #f7991c !important;
    @include custom-toastr;
  }

  .toast-info {
    background-image: url("../assets/svg/info-circle-solid.svg");
    border-left: 8px solid #1e69e4 !important;
    @include custom-toastr;
  }

  .toast-title {
    color: #414141;
    font-size: 14px;
    text-transform: capitalize;
  }

  .toast-message {
    color: #969696;
    font-size: 13px;
    text-transform: capitalize;
  }

  button.toast-close-button {
    color: #969696;
  }
}

.location .mat-dialog-container {
  height: 400px !important;
  width: 600px !important;
  border-radius: 10px;
}
.add-button {
  background: #3552ad;
  box-shadow: 0px 3px 6px #00000005;
  color: white !important;
  border-radius: 5px !important;
  margin-right: 0;
  border: none !important;
  height: 46px;
  line-height: 1.8 !important;
  padding: 0px 12px 0px 7px !important;
}
// new design system end
