@import "src/theme/theme-variables";

/**
custom logic for rtl
 */
#app-shell[dir="rtl"] {
  .field-container {
    &.file_upload_details {
      .input-container {
        background-color: $porcelain;
        border: 1px dashed $rock-blue;
        width: 80%;
        border-radius: 8px;
        color: $rock-blue;
        padding: 15px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        .action-button {
          margin-right: 45%;

          button {
            background-color: $porcelain;
            border: 1px solid $rock-blue;
            color: $rock-blue;
          }
        }
      }
    }
  }
}
