// helper utilities

.full-width {
  width: 100%;
}

.width_30 {
  width: 40%;
}

.full-height {
  height: 100%;
}

.layout-wrap {
  flex-wrap: wrap;
}

.position-relative {
  position: relative;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex !important;
}

.text-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

/**
padding helper utilities
 */
.pt-1 {
  padding-top: 2px !important;
}

.pt-2 {
  padding-top: 4px !important;
}

.pt-3 {
  padding-top: 6px !important;
}

.pt-4 {
  padding-top: 8px !important;
}

.pt-5 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-1 {
  padding-bottom: 2px !important;
}

.pb-2 {
  padding-bottom: 4px !important;
}

.pb-3 {
  padding-bottom: 6px !important;
}

.pb-4 {
  padding-bottom: 8px !important;
}

.pb-5 {
  padding-bottom: 10px !important;
}

.py-1 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-2 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-3 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-4 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-5 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-1 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.px-2 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-3 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.px-4 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-5 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-6 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pl-1 {
  padding-left: 2px !important;
}

.pl-2 {
  padding-left: 4px !important;
}

.pl-3 {
  padding-left: 6px !important;
}

.pl-4 {
  padding-left: 8px !important;
}

.pl-5 {
  padding-left: 10px !important;
}

.pl-25px {
  padding-left: 25px !important;
}

/**
  start margin helper utilities
*/

.ml-1 {
  margin-left: 2px !important;
}

.ml-2 {
  margin-left: 4px !important;
}

.ml-3 {
  margin-left: 6px !important;
}

.ml-4 {
  margin-left: 8px !important;
}

.ml-5 {
  margin-left: 10px !important;
}

.mr-1 {
  margin-right: 2px !important;
}

.mr-2 {
  margin-right: 4px !important;
}

.mr-3 {
  margin-right: 6px !important;
}

.mr-4 {
  margin-right: 8px !important;
}

.mr-5 {
  margin-right: 10px !important;
}

.mt-1 {
  margin-top: 2px !important;
}

.mt-2 {
  margin-top: 4px !important;
}

.mt-3 {
  margin-top: 6px !important;
}

.mt-4 {
  margin-top: 8px !important;
}

.mt-5 {
  margin-top: 10px !important;
}

.mt-6 {
  margin-top: 12px !important;
}

.mb-1 {
  margin-bottom: 2px !important;
}

.mb-2 {
  margin-bottom: 4px !important;
}

.mb-3 {
  margin-bottom: 6px !important;
}

.mb-4 {
  margin-bottom: 8px !important;
}

.mb-5 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.my-1 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.my-2 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-3 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.my-4 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-5 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mr-1 {
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.mx-2 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-3 {
  margin-right: 6px !important;
  margin-left: 6px !important;
}

.mx-4 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-5 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.m-0 {
  margin: 0px !important;
}

/**
  end margin helper utilities
*/

.flex-items-center {
  align-items: center;
}

.img-fluid {
  max-width: 100%;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-secondary {
  background-color: $color-secondary;
}

.bg-danger {
  background-color: $color-danger;
}

//TODO : Add color variable
.bg-confirm {
  background-color: #3552ad;
}

.bg-success {
  background-color: $color-success;
}

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.text-gray {
  color: #a1a1a1;
}

.display-inline-flex {
  display: inline-flex;
}

.mrg-btm {
  margin-bottom: 24px;
}

.mrg-btm-12 {
  margin-bottom: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: bold;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-column {
  flex-direction: column;
}

[hidden] {
  display: none !important;
}

.heightAnimated {
  max-height: 5000px;
  transition: all 0.3s ease-in-out;
}

.hiddenWithAnimate {
  max-height: 0;
  overflow: hidden;
}

.flex-wrap {
  flex-wrap: wrap;
}

.centeredCell {
  .cell-inner-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.flex-basis-100 {
  flex-basis: 100%;
}

.border-radius-none {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.expand-cols-2 {
  grid-column: 1 / span 2;
  width: 100%;
}

.expand-cols-4 {
  grid-column: 1 / span 4;
  width: 100%;
}

.red-clockify-icon {
  path {
    fill: #d41616 !important;
  }
}

.orange-clockify-icon {
  path {
    fill: #ee9715 !important;
  }
}

.green-clockify-icon {
  path {
    fill: #07be69 !important;
  }
}

.gray-clockify-icon {
  path {
    fill: #525252 !important;
  }
}

.active-acc-key {
  path {
    fill: #07be69 !important;
  }
}

.not-active-acc-key {
  path {
    fill: #707070;
  }
}

.active-last-signal {
  path {
    fill: #07be69 !important;
  }
}

.not-active-last-signal {
  path {
    fill: #c73838 !important;
  }
}
.empty-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  mat-icon.icon-list {
    width: 140px;
    height: 140px;
    font-size: 140px;
    overflow: visible;
  }
  .title {
    color: #2053a6;
    font-size: 22px;
    margin: 2rem 0 0.5rem 0 !important;
    font-weight: 600;
  }
  .des {
    color: #94a3b8;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
}
