@mixin custom-toastr {
  left: 80% !important;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 5px !important;
  background-position: 15px center;
  background-color: #f8fdf9;
  box-shadow: 0 0 12px #999;
  color: #fff;
}
