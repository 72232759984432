@import "src/theme/theme-variables";

app-settings {
  .form-spacing {
    &.lg {
      padding: 0;
    }
  }

  app-nav-menu {
    .page_heading {
      margin-top: -82px;
      margin-left: 60px;
      //width: fit-content;
      //align-self: flex-end;
    }
  }
}

app-vendors-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 80px;
        }
      }
    }
  }
}

app-documents {
  .table-column-header {
    display: none;
  }

  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 100px;
        }
      }
    }
  }
}

app-roles-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 60px;
        }
      }
    }
  }
}

app-fuel-histories-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 120px;
        }
      }
    }
  }
}

app-parts-by-usage-report-list {
  .table-style {
    .tbl-header {
      th,
      tr {
        background-color: white;
      }
    }

    tbody {
      tr {
        background-color: white;
      }
    }
  }
}

app-parts-by-vehicle-report-list {
  .mat-table {
    border-collapse: separate;
    background-color: #eef5f9;
    // border-spacing: 0 8px !important;
    border-radius: 8px;

    thead {
      background-color: white;

      .mat-header-row {
        background-color: white;

        .mat-header-cell {
          font-weight: normal;
          font-size: 14px;
          border-bottom: 1px solid #ccd3e4;
          color: #98a6c9;
          padding: 0 10px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        .mat-column-actions {
          width: 20px !important;
          color: black !important;
          cursor: pointer;
        }
      }
    }

    tbody {
      .mat-row {
        background-color: white;
        cursor: pointer;

        .mat-column-expandedDetail {
          border-top: 0 !important;
          border-radius: 0 !important;
          // background-color: rgba(0, 0, 0, 0.05);
        }

        .mat-cell {
          border-top: #eef5f9 solid 15px;
          padding: 25px;
        }
      }
    }
  }
}

app-workorder-per-vehicle-report-list {
  .mat-table {
    border-collapse: separate;
    background-color: #eef5f9;
    // border-spacing: 0 8px !important;
    border-radius: 8px;

    thead {
      background-color: white;

      .mat-header-row {
        background-color: white;

        .mat-header-cell {
          font-weight: normal;
          font-size: 14px;
          border-bottom: 1px solid #ccd3e4;
          color: #98a6c9;
          padding: 0 10px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        .mat-column-actions {
          width: 20px !important;
          color: black !important;
          cursor: pointer;
        }
      }
    }

    tbody {
      .mat-row {
        background-color: white;
        cursor: pointer;

        .mat-column-expandedDetail {
          border-top: 0 !important;
          border-radius: 0 !important;
        }

        .mat-cell {
          border-top: #eef5f9 solid 15px;
          padding: 25px;
        }
      }
    }
  }
}

app-service-task-summary-report-list {
  .table-style {
    .tbl-header {
      th,
      tr {
        background-color: white;
      }
    }

    tbody {
      tr {
        background-color: white;
      }
    }
  }
}

app-insurance-payments-list,
app-vehicle-issues-list,
app-vehicle-fuel-histories-list {
  .core-list {
    .list-grid-options {
      app-list-grid-options {
        span.title {
          color: black !important;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 80px;
        }
      }
    }
  }
}

app-vehicle-status-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 60px;
        }
      }
    }
  }
}

app-vehicle-groups-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 100px;
        }
      }
    }
  }
}

app-vehicles-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 100px;
        }
      }
    }
  }
}

app-users-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 120px;
        }
      }
    }
  }
}

app-issues-list,
app-vehicle-issues-list,
app-service-entry-list,
app-work-order-list {
  .bg-transparent {
    .img-container {
      background-color: transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-size: cover;
    }
  }

  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 90px;
        }
      }
    }
  }
}

app-vendor-profile {
  .view-container {
    .photo-container {
      min-width: 150px;
      background-color: aliceblue;
    }
  }
}

app-vehicle-profile {
  .view-container {
    .photo-container {
      min-width: 150px;
    }
  }
}

mat-accordion {
  &.accordion-style-1 {
    mat-expansion-panel {
      padding: 0 20px;

      .minus {
        display: none;
      }

      &.mat-expanded {
        .minus {
          display: block;
        }

        .plus {
          display: none;
        }
      }

      &.mat-expansion-panel:not([class*="mat-elevation-z"]) {
        box-shadow: none;
      }

      mat-expansion-panel-header {
        .mat-expansion-indicator {
          display: none;
        }

        .mat-content {
          justify-content: space-between;
        }
      }
    }
  }
}

.main_group,
// we can use only this ,to be unified through all tabs groups as they all have the same UI.
.vehicles_main_group,
.company-data,
.applications,
.required-info,
.users-form {
  display: flex;
  flex-direction: row !important;

  .mat-tab-header {
    display: flex;
    border: 0;

    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          display: flex;
          flex-direction: column;

          .mat-tab-label {
            justify-content: flex-start;
            transition: all 0.2s ease-in-out;

            &::after {
              content: "";
              position: absolute;
              display: block;
              height: 0;
              left: 0;
              right: 0;
              width: 1px;
              background-color: #273c75;
              transition: all 0.2s ease-in-out;
            }

            &.mat-tab-label-active {
              //border-left: 5px solid #273c75;
              background-color: rgba(241, 244, 249, 0.5);
              position: relative;
              opacity: 1;
              color: $color-primary;

              img.tab-icon {
                filter: invert(0%) sepia(92%) saturate(100) hue-rotate(201deg) brightness(100%) contrast(100%);
              }

              &::after {
                height: 100%;
                width: 5px;
              }
            }
          }
        }
      }
    }
  }

  .mat-tab-body-wrapper {
    display: flex;
    flex: 1 1 auto;
    padding: 0 30px; // according to the design.
    background-color: #fff;
  }

  mat-ink-bar {
    display: none;
  }
}

app-vehicles-form {
  .default-form {
    max-width: none;

    .form-section {
      &.defaultSection {
        padding: 0;
      }
    }
  }
}

app-vehicles-dashboard,
app-parts-dashboard {
  .page_heading {
    margin-bottom: 0;
  }

  .core-list {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 0;
    background-color: white;
    padding: 10px 15px;
    border-radius: 0 0 8px 8px;
  }
}

//mat-form-field {
//margin-bottom: 10px;
//}

app-issues-form {
  .default-form {
    app-core-form-content {
      .field-container {
        mat-checkbox {
          margin-bottom: 24px;
        }
      }
    }
  }
}

app-work-order-form {
  .ps__rail-y {
    right: auto !important;
    left: 0;
  }

  .default-form {
    app-core-form-content {
      .field-container {
        mat-checkbox {
          margin-bottom: 24px;
          margin-top: -5px;
        }
      }
    }
  }

  .ng-select.service-tasks-ng-select {
    &:not(.ng-select-focused) {
      .ng-placeholder {
        transform: none !important;
      }
    }
  }
}

app-filters {
  height: 100%;
}

breadcrumb {
  .mat-button {
    .mat-button-wrapper {
      font-weight: bold;
    }
  }
}

ngx-material-timepicker-container {
  .timepicker-button {
    margin: 0 8px;
  }

  ngx-material-timepicker-content {
    .timepicker__header {
      background-color: #324462 !important;

      .timepicker-dial__container {
        justify-content: center;
      }
    }

    .timepicker__main-content {
      .timepicker__body {
        .clock-face__container {
          .clock-face__number--outer {
            span.active {
              background-color: #324462;
            }
          }
        }

        .clock-face__clock-hand {
          background-color: #324462;
        }
      }
    }
  }

  .timepicker__main-content {
    div.timepicker__actions {
      justify-content: center !important;

      ngx-material-timepicker-button {
        .timepicker-button {
          border: 1px solid #324462;
          border-radius: 4px;
          margin: 2px;

          span {
            color: #324462;
          }
        }
      }
    }
  }
}

ngx-material-timepicker-minutes-face {
  ngx-material-timepicker-face {
    .clock-face__clock-hand_minute:before {
      border-color: #324462 !important;
    }
  }
}

mat-sidenav-container {
  mat-sidenav {
    &.main-menu {
      z-index: 3;

      > .mat-drawer-inner-container {
        overflow: hidden !important;

        > nav {
          height: 100%;

          .mat-list {
            height: 100%;
          }
        }

        .scrollable-container {
          position: relative;
          height: calc(100% - 64px);
        }
      }
    }
  }
}

app-line-item-parts {
  mat-list.mat-list-base {
    margin-bottom: 12px !important;
  }
}

app-line-item-labors,
app-line-item-parts {
  .left-content .mat-figure {
    justify-content: flex-start;
  }

  .right-content .mat-figure {
    justify-content: flex-end;
  }
}

app-work-order-form,
app-service-entry-form {
  .issues_card {
    .issues-tabs {
      .mat-tab-label:last-child {
        //margin-left: auto;
        //margin-right: 0;
        margin-left: var(--primary-margin-200);
        margin-right: var(--secondary-margin-200);
        color: $color-secondary;
        opacity: 1;

        @media only screen and (max-width: 1024px) {
          margin-left: var(--primary-margin-40);
          margin-right: var(--secondary-margin-40);
        }

        @media only screen and (max-width: 768px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .form-spacing.md {
    padding: 0 7.5%;
  }

  .default-form {
    //max-width: 100%;
  }

  .mat-tab-header-pagination {
    display: flex !important;
  }
}

app-line-item-tasks {
  .field-container {
    padding-right: 15px;
  }
}

app-issues-select-form {
  .mat-card {
    box-shadow: none !important;

    .default-form {
      min-width: 150px;
      //max-width: inherit;
      width: 100%;
    }
  }
}

app-meters-list {
  .boolean-icon {
    mat-icon {
      fill: $rock-blue;
      color: $rock-blue;
    }

    &.true {
      mat-icon {
        fill: #3d3d3d !important;
        color: #3d3d3d !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

app-vehicle-status-list,
app-roles-list,
app-fuel-types-list {
  .table-style {
    .tbl-header {
      th {
        &.actions-head {
          width: 100px;
        }
      }
    }
  }
}

app-cost {
  .select-type {
    mat-label {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.45);
    }

    mat-select {
      border-bottom: 1px solid #949494;

      .mat-select-trigger {
        padding-bottom: 4px;
      }
    }
  }
}

.cdk-overlay-pane {
  .mat-select-panel {
    margin-top: 28px !important;
  }
}

app-activity-log-list {
  .table-style.infinite-scrolling thead,
  .table-style.no_data .tbl-header {
    display: none;
  }

  .table-style .actions-data-row {
    display: none;
  }
}

app-notifications-list {
  app-list-grid {
    .table-overflow {
      border-radius: 0 !important;
    }
  }

  .table-style.infinite-scrolling thead,
  .table-style.no_data .tbl-header {
    display: none;
  }

  .table-style .actions-data-row {
    display: none;
  }

  .table-style.infinite-scrolling tbody tr {
    border-bottom: 8px solid white;

    .locked {
      background: none !important;
    }

    td {
      padding: 0;

      &:hover {
        background-color: unset !important;
        color: unset !important;
      }
    }
  }
}

app-comments {
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }
}

app-date-picker,
app-time-picker {
  input[readonly="true"] {
    cursor: pointer !important;

    &[disabled] {
      cursor: not-allowed !important;
    }
  }
}

app-status-report-report-list {
  .clear-suffix-margins {
    .item-suffix {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

app-inspections-form {
  .mat-expansion-panel-body {
    padding: 0px;
  }
}

app-inspections-completed-form {
  .mat-expansion-panel-content {
    padding: 0px 30px;
  }

  .mat-radio-button {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: #97a5cb;
        }
      }
    }
  }

  mat-checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        .mat-checkbox-frame {
          border-color: #97a5cb;
        }
      }
    }
  }
}

app-core-form-content {
  .touranchor--is-active {
    position: relative;
    z-index: 1000;
    pointer-events: auto;
    padding: 8px 8px 0px 8px;

    &::before {
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.7) 0px 0px 0px 999px;
      content: "";
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      bottom: 5px;
    }
  }

  ngx-mat-intl-tel-input {
    // margin: 5px 0;
    position: relative;
    display: flex;

    .country-selector {
      display: flex;
      align-items: center;
      opacity: 1 !important;
    }
  }
}

app-vehicle-renewal-reminders {
  .table-style {
    .tbl-header {
      th.actions-head {
        display: none;
      }
    }
  }

  .table-style .actions-data-row {
    display: none;
  }
}

app-nav-menu {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

app-date-picker {
  .mat-form-field-hint-wrapper {
    width: 100%;
  }
}

// The follwing code can be generalized over the whole app fields (recommended)
app-login,
app-forgot-password,
app-change-password,
app-core-form-content,
app-warehouses-form .building-form-container {
  // field border color on hover = main color instead of black
  .mat-form-field-appearance-outline .mat-form-field-outline-thick,
  .ng-select .ng-select-container.ng-appearance-outline:hover:after {
    color: mat.get-color-from-palette($app-primary);
    border-color: mat.get-color-from-palette($app-primary);
  }

  // Centeralize field text/placeholder vertically - once the lable cleared
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 1em 0;
  }

  // Centeralize field prefix/suffix vertically
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    color: $color-primary;
  }

  .mat-form-field-flex {
    align-items: center;
  }
}

app-clients-edit {
  .mat-expansion-panel-body {
    flex-direction: column !important;
    padding: 0px !important;
  }
}

app-clients-add,
app-clients-edit,
app-admins-form {
  .form-spacing.lg {
    padding: 0 !important;
  }

  .default-form {
    // max-width: 900px !important;
    // max-width: 1200px !important;
  }

  .mat-slide-toggle-content {
    font-weight: normal;
  }
}

// new design system for Afaqy Products
app-core-form {
  .form-body-content {
    background: #fff;
  }
}

app-list-grid {
  .mat-icon-button {
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
  }
}

.custom-actions-menu + div div .mat-menu-panel {
  width: 100px;
  height: 104px;

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  button {
    padding: 0 10px;
    font-size: 13px;
    color: #555555;
    line-height: 40px;
    height: 36px;
  }
}

// that's for styling the header add-button menu.
// the menu appended - on the fly - to the document root , not under any parent component.
// will specify it the backdropClass = 'customized-adds-menu'
.customized-adds-menu + div div .mat-menu-panel {
  margin-top: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  min-height: 48px;

  button.mat-menu-item {
    margin: 5px 0;
  }
}

.customized-adds-menu + div .cdk-overlay-pane {
  // position: relative;
  &::before {
    position: absolute;
    content: "";
    top: 5px;
    left: 5%;
    // background-color: #fff;
    width: 0px;
    height: 0px;
    z-index: 9999;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
}

app-users-list {
  .actions-th-width {
    width: 70px;
  }
}

app-leaflet-map {
  mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        height: 41px;
      }
    }
  }
}

app-warehouses-form {
  .mat-form-field-flex {
    height: 41px !important;
  }

  .mat-form-field-suffix,
  .mat-form-field-infix {
    align-self: center !important;
  }

  mat-label {
    font-size: 12px;
  }

  .buildings-container
    .building-form-container
    .field-container
    .mat-form-field-appearance-outline
    .mat-form-field-outline {
    background: #fff;
  }
}

app-dynamic-dialog {
  height: 100%;
}

app-users-form {
  .mat-tab-body-wrapper {
    padding: 0 0px !important;
  }

  .form-section {
    margin: 0 30px;
  }
}

app-clients-add {
  mat-button-toggle-group {
    width: fit-content !important;
  }

  .mat-button-toggle {
    padding-right: 6px;
    padding-left: 6px;
  }

  .default-form .field-container .mat-button-toggle-group .mat-button-toggle:first-of-type {
    width: fit-content;
    height: 33px;
    border-radius: 6px;
  }

  .default-form .field-container .mat-button-toggle-group .mat-button-toggle:last-of-type {
    width: fit-content;
    height: 33px;
    border-radius: 6px;
  }
}

// remove un-needed additional section in the color-picker
.ngx-mat-color-canvas .color-canvas-row .zone-inputs,
.ngx-mat-color-canvas .color-canvas-row:nth-of-type(2) {
  display: none !important;
}

.filters-inline app-filters-inline .filter-inline-container form .mat-form-field {
  vertical-align: -webkit-baseline-middle;
}

app-configure-sensor {
  .default-form .form-section {
    padding: 0;
  }

  .main_group .mat-tab-body-wrapper {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  app-calibration {
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.5em 0;
      border-top: 0.5em solid transparent;
    }
  }

  .default-form .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  // Encrease width of the tab body
  .mat-tab-body-wrapper {
    max-width: 80%;
  }

  .core-form-header {
    width: calc(100% - 50px) !important;
  }

  // mat-button toggle overrides.
  .mat-button-toggle-group {
    height: 33px !important;
    margin-top: 0px;
  }

  .mat-button-toggle {
    width: 33px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-button-toggle-group span {
    line-height: 0 !important;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0;
  }

  .mat-tab-header {
    width: 190px;
  }

  .mat-tab-header-pagination {
    display: none !important;
  }

  .defaultSection:has(.grid-item) {
    background-color: #fff;
    // margin: 5px;
    padding: 30px;
    width: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
  }
}

app-content-management-system {
  .mat-tab-header-pagination {
    display: none !important;
  }

  .core-form-header {
    display: none !important;
  }

  .core-form-component-body {
    margin-top: 10px;
  }
}

app-clients-detail {
  .mat-button-wrapper {
    color: #fff !important;
  }

  .mat-menu-panel {
    .mat-menu-content {
      padding: 0 !important;

      .mat-menu-item {
        height: 35px !important;
      }
    }
  }
}

app-clients-list {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0px !important;
  }

  .customActionsIconButton {
    margin: 0px 4px;
  }
}

app-clients-detail {
  .mat-icon-button {
    width: fit-content !important;
  }
}

app-configure-sensor {
  .mat-card {
    padding: 16px 0 !important;
  }
}

app-configure-sensor {
  .page_footer {
    width: 100%;
    display: initial !important;
  }
}

app-list-grid-options {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
  }
}
app-form-footer {
  width: 100% !important;
}

app-setup-account {
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0 !important;
  }
}

.assign-command-dialog .mat-dialog-container {
  width: 30vw !important;
  height: 330px !important;
}
app-commands {
  .ng-select {
    padding-bottom: 0 !important;
  }
}
