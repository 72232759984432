/*
 * Application global variables.
 */
@use "@angular/material" as mat;
// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

/* For use in src/lib/core/theming/_palette.scss */
$md-AP: (
  50: #e5e8ee,
  100: #bec5d6,
  200: #e6ecf5,
  // light degree
  300: #68779e,
  400: #47598a,
  500: #3552ad,
  // main color
  600: #23366d,
  700: #1d2e62,
  800: #172758,
  900: #0e1a45,
  A100: #7e96ff,
  A200: #4b6dff,
  A400: #1843ff,
  A700: #002ffd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

$app-primary: mat.define-palette($md-AP);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

$color-primary: mat.get-color-from-palette($app-primary);
$color-secondary: var(--color-secondary);
// $color-primary: #1e69e4;
// $color-secondary: #273c75;
$color-danger: #ff0000;
$color-success: #1a8922;
$color-menu: #324462;
$host-background: #f5f5f5;
$cards-title: #202020;
$rock-blue: #97a5cb;
$orange-peel: #ff9900;
$porcelain: #eef0f2;
$biscay: #1b325f;
$thead-color: #f5f7ff;
$even-tr-color: #f7fafd;

$primary-direction: var(--primary-direction);
$secondary-direction: var(--secondary-direction);

$primary-text-align: var(--primary-text-align);
$secondary-text-align: var(--secondary-text-align);

$primary-flex-justify: var(--primary-flex-justify);
$secondary-flex-justify: var(--secondary-flex-justify);

$primary-flex-direction: var(--primary-flex-direction);
$secondary-flex-direction: var(--secondary-flex-direction);

$gray-600: #d8d8d8;
$color-dark: #242121;
$body-bg: #f2f5f9;

:root {
  --primary-direction: left; // ltr
  --secondary-direction: right; // ltr

  --primary-text-align: end; // ltr
  --secondary-text-align: start; // ltr

  --primary-flex-justify: flex-start;
  --secondary-flex-justify: flex-end;

  --primary-flex-direction: ltr;
  --secondary-flex-direction: rtl;

  --color-primary: #3552ad;
}
