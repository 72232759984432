/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use "@angular/material" as mat;

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~material-design-icons-iconfont/src/material-design-icons";

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Theme customization
@import "~ngx-toastr/toastr";
@import "theme/mdi-icons";
@import "~@ng-select/ng-select/themes/material.theme.css";
//@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-perfect-scrollbar/dist/lib/perfect-scrollbar.component.css";
@import "theme/perfect-scrollbar.directive";
//@import "theme/ng-select-mat-theme";

@import "theme/mat-table";
@import "theme/theme";

@import "theme/override-styles";
@import "theme/override-components";

@import "theme/rtl";

@import "theme/utilities";
